@function calc-em($target, $context: $base-fontsize) {
    @return $target / $context * 1em;
}

@mixin background-gradient($color1, $color2, $direction) {
    background: $color2;
    background-repeat: repeat-x;

    @if $direction == vertical {
        background: -ms-linear-gradient(top, $color1 0%, $color2 100%); /* IE10 Consumer Preview */
        background: -moz-linear-gradient(top, $color1 0%, $color2 100%); /* Opera */
        background: -o-linear-gradient(top, $color1 0%, $color2 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0, $color1), color-stop(1, $color2));/* Webkit (Safari/Chrome 10) */
        background: -webkit-linear-gradient(top, $color1 0%, $color2 100%); /* Webkit (Chrome 11+) */
        background: linear-gradient(to bottom, $color1 0%, $color2 100%); /* W3C Markup, IE10 Release Preview */
    } @else if $direction == diagonal {
        background: -webkit-linear-gradient(left top, $color1, $color2); /* For Safari */
        background: -o-linear-gradient(bottom right, $color1, $color2); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(bottom right, $color1, $color2); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to bottom right, $color1, $color2); /* Standard syntax (must be last) */
    } @else if $direction == horizontal {
        background: -webkit-linear-gradient(left, $color1, $color2); /* For Safari */
        background: -o-linear-gradient(right, $color1, $color2); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(right, $color1, $color2); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to right, $color1, $color2); /* Standard syntax */
    }
}

@mixin border-radius($dimension) {
    -webkit-border-radius: $dimension;
       -moz-border-radius: $dimension;
            border-radius: $dimension;
}

@mixin box-shadow($horizontalOffset, $verticalOffset, $blurRadius, $spreadRadius, $color) {
    -webkit-box-shadow: $horizontalOffset $verticalOffset $blurRadius $spreadRadius $color;
        -moz-box-shadow: $horizontalOffset $verticalOffset $blurRadius $spreadRadius $color;
            box-shadow: $horizontalOffset $verticalOffset $blurRadius $spreadRadius $color;
}

@mixin inline-block() {
    display: inline-block;
    *display: inline;
    zoom: 1;
}

@mixin clearfix() {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after { clear: both; }
}

@mixin transition($type, $duration, $easing) {
    transition-property: $type;
    transition-duration: $duration;
    transition-timing-function: $easing;
    /* Safari */
    -webkit-backface-visibility: hidden;
    -webkit-transition-property: $type;
    -webkit-transition-duration: $duration;
    -webkit-transition-timing-function: $easing;
}

@mixin normalise-list() {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

@mixin sprite($x, $y, $h, $w) {
    background: transparent url('../images/sprite.png') no-repeat $x $y;
    height: $h;
    width: $w;
}

@mixin transform($property) {
    -webkit-transform: $property;
        -ms-transform: $property;
            transform: $property;
}

//CSS3 element rotation
@mixin rotate ($angle: 90deg, $origin: center) {
    -webkit-transform: rotate($angle); //* Safari and Chrome */
        -ms-transform: rotate($angle); //* IE 9 */
            transform: rotate($angle);

    @if ($origin != center) {
        @include origin($origin);
    }
}

//CSS3 element scale
@mixin scale ($scale: 1, $origin: center) {
    -webkit-transform: scale($scale);
        -ms-transform: scale($scale);
            transform: scale($scale);

    @if ($origin != center) {
        @include origin($origin);
    }
}

@mixin origin ($origin: 0px 0px) {
    -webkit-transform-origin: $origin;
        -ms-transform-origin: $origin;
            transform-origin: $origin;
}

// Absolute Center
@mixin absolute-center() {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}

// Image Responsive
@mixin img-responsive() {
    display: block;
    height: auto;
    margin: auto;
    max-width: 100%;
    width: 100%;
}

@mixin icomoon($glyph) {
    @extend %icomoon;
    content: $glyph;
}

// Generate font-size and line-height, with standard margin bottom
@mixin font-size-with-line-height($font-size, $line-height-multiplier, $context: $base-fontsize) {
    font-size: calc-em($font-size, $context);
    line-height: ($base-lineheight * $line-height-multiplier * $context / $font-size);
    margin-top: 0;
    margin-bottom: ($base-lineheight * $context / $font-size) + em;
}

@mixin vertical-middle() {
    &:before {
        content: "";
        width: 0;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
}
