// related-list styles
.related-list {
  margin-bottom: 60px;

  p {
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 12px;
  }
}
