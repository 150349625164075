// facets-container styles
.facets-container {
  width: 100%;
}

@media only screen and (min-width: $bpDesktop) {
  .facets-container {
    width: 25%;
    padding-right: 30px;
  }
}

.btn-filter {
  margin-bottom: 20px;
}
