// results-container styles
.results-container {
  width: 100%;

  &.full-width {
    width: 100%;
  }
}

@media only screen and (min-width: $bpDesktop) {
  .results-container {
    width: 75%;
  }
}
