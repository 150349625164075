// nda-search styles
.nda-search {
  margin-left: -15px;
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .nda-search {
    margin-left: 0px;
  }
}
