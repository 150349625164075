// nda-medal styles
.lnk-award {
  text-align: center;

  &:visited {
    color: $visitedLink;
  }

  .award-img {
    margin: auto auto 10px;
  }
}
