/*
.grayscale img { filter:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0'/></filter></svg>#grayscale"); -webkit-filter:grayscale(1); filter:grayscale(1); filter:gray; }
*/

.grayscale {
  .page-options .pg-opt-icon::before, .aside-widget.tags a,
  .pagination > li > a, .pagination > li > span, .featured-notes .featured-content a,
  .related-links a, a, a:visited, a:focus {
    color: #777;
  }
}

.grayscale .related-links a:hover, .grayscale .pagination > li > a:hover, .grayscale .pagination > li > span,
.grayscale .home-newsroom-links a:hover .home-newsroom-title, .grayscale .aside-widget.tags a:hover,
.grayscale .home-newsroom-links a.active .home-newsroom-title, .grayscale a:hover, .grayscale a:active, .grayscale .icon-star,
.grayscale .breadcrumbs a:hover, .grayscale .page-options .pg-opt-icon:hover::before, .grayscale .featured-notes .featured-content a:hover {
  color: #999;
}

.grayscale .page-options .txtsize-disabled::before {
  color: #ccc;
}

@media only screen and (min-width: 768px){
  .grayscale .featured-notes .featured-content a {
    color: #FFF;
  }
}

.grayscale .slick-prev:hover, .grayscale .slick-prev:focus,
.grayscale .slick-next:hover, .grayscale .slick-next:focus,
.grayscale .btn-getupdates, .grayscale .btn-subscribe,
.grayscale .btt:hover .arrow-icon,
.grayscale .bar-top, .grayscale .btn-pmo {
  background: #666;
}

.grayscale .btn-getupdates:hover, .grayscale .btn-subscribe:hover, .grayscale .btn-pmo:hover {
  background: #999;
}

.grayscale .notification-bar {
  background: #ccc;
}

.grayscale .inline-search input {
  background: #EEE;
}

.grayscale, .grayscale .related-title-container .rt-title {
  background: #f6f6f6;
}

.grayscale .aw-number-container, .grayscale .div-line, .grayscale .topics-content,
.grayscale .star-line-container::before, .grayscale .star-line-container::after  {
  border-top: 1px solid #CCC;
}

.grayscale .aside-title, .grayscale .snippet:last-child, .grayscale  .snippet, .grayscale .accordion .accordion-item,
.grayscale .title-line, .grayscale .related-title-container .rt-line, .grayscale .home-newsroom-item {
  border-bottom: 1px solid #CCC;
}

@media only screen and (min-width: 768px){
  .grayscale .home-newsroom .nr-col {
    border-top: 1px solid #CCC;
  }
  .grayscale .home-newsroom-item:nth-child(odd) {
    border-right: 1px solid #CCC;
  }
  .grayscale .home-newsroom-item {
    border-bottom: none;
  }
}
.grayscale #base-content {
  background-image: url(../images/footer-pattern-grayscale.png) !important;
}

.grayscale footer {
  border-top: 1px solid #666; background: #FFF;
}

.grayscale .btt, .grayscale .btt:visited, .grayscale .btt:hover, .grayscale .btt:focus {
  color: #FFF;
}

.grayscale textarea:focus, .grayscale input:focus, .grayscale .uneditable-input:focus {
  border-color: #666 !important;
}

.grayscale .sidebar {
  border-top: 4px solid #ccc;
}

.grayscale .serp-container .selected { background: #999; color: #FFF; }
.grayscale .btn-pill { border: 1px solid #999; }
.grayscale .btn-pill.pill-active { background: #999; border: 1px solid #999; color: #FFF; }
.no-js .grayscale .btn-pill:hover, .no-js .grayscale .btn-pill:focus,
.no-touch .grayscale .btn-pill:hover, .js.touch .grayscale .btn-pill:active {
  border: 1px solid #666; background: #666;
}

/*.node-cabinet-appointments .field-designation2,
.node-cabinet-appointments .field-description,
.node-cabinet-appointments img{
    text-align: left;
    margin: auto;
    margin-right: 0;
}
.node-cabinet-appointments .field-designation2,
.node-cabinet-appointments .field-description {
    margin: 0 auto 1em;
}*/

.container-inline-date {
  > .form-item {
    margin-right: 0;
  }

  .form-item {
    &, input {
      width: 100%;
    }
  }
}

.videoWrapper, .flex-view {
  max-width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
  height: auto;
  margin: 0 !important;

  * {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.webform-client-form {
  .resizable-textarea {
    textarea {
      border: solid 1px #DDD;
      background: #FFF;
    }
  }

  .captcha {
    border: solid 1px #DDD;
    margin: 20px 0;
    padding: 20px;

    .captcha-title {
      background: #fdfbf7;
      position: absolute;
      text-align: center;
      margin: auto;
      margin-top: -30px;
      margin-left: 25px;
      width: 160px;
      right: 0;
      left: 0;
    }

    img {
      border: solid 1px #DDD;
    }

    object, a {
      vertical-align: middle;
      margin: 15px 0;
    }

    #edit-captcha-response {
      border: solid 1px #DDD;
      background: #FFF;
      width: 70px;
    }
  }

  .btn-primary {
    color: inherit;
    background: #DDD;
    border: none;

    &:hover {
      color: inherit;
      background-color: #E9EBEE;
    }
  }
}

/*------------------------------------*  jcarousel..
\*------------------------------------*/



.carousel-standard {
  .jc-item {
    width: 360px;

     a {
      display: block;
    }
  }
}

@media only screen and (min-width: $bpTablet) {
  .carousel-standard {
    width: 650px;
    margin-left: 50px;

    .jc-item {
      width: 375px;
      padding-right: 20px;
    }
  }
}

@media only screen and (min-width: $bpDesktop) {
  .carousel-standard {
    width: 870px;
    margin-left: 50px;

    .jc-item {
      width: 485px;
      padding-right: 20px;
    }
  }
}

@media only screen and (min-width: $bpLgDesktop) {
  .carousel-standard {
    width: 1070px;
    margin-left: 50px;

    .jc-item {
      width: 360px;
      padding-right: 20px;
    }
  }
}

.carousel-award-winner {
  .jc-item {
    width: 360px;
    text-align: center;
  }

  .jcarousel-control {
    &.jcarousel-control-next {
      right: 0;
    }

    &.jcarousel-control-prev {
      left: 0;
    }
  }
}

@media only screen and (min-width: $bpTablet) {
  .carousel-award-winner {
    width: 460px;
    margin-left: 145px;

    .jc-item {
      width: 460px;
      margin-right: 20px;
    }
  }

  .jcarousel-control {
    &.jcarousel-control-next {
      right: -50px;
    }

    &.jcarousel-control-prev {
      left: -50px;
    }
  }
}

@media only screen and (min-width: $bpDesktop) {
  .carousel-award-winner {
    width: 970px;
    margin-left: 0px;

    .jc-item {
      width: 970px;
      margin-right: 20px;
    }
  }

  .jcarousel-control {
    &.jcarousel-control-next {
      right: 10px;
    }

    &.jcarousel-control-prev {
      left: 10px;
    }
  }
}

@media only screen and (min-width: $bpLgDesktop) {
  .carousel-award-winner {
    width: 1170px;
    margin-left: 0px;

    .jc-item {
      width: 1170px;
      margin-right: 20px;
    }
  }
}

.carousel-home {
  width: 100%;
}

@media only screen and (min-width: $bpTablet) {
  .carousel-home {
    .jcarousel-control {
      &.jcarousel-control-next {
        right: 20px;
      }

      &.jcarousel-control-prev {
        left: 20px;
      }
    }
  }
}

@media only screen and (min-width: $bpDesktop) {
  // .col-height {
  //   height: 170px;
  //   margin-bottom: 0px;
  // }

  .carousel-home {
    .jcarousel-control {
      &.jcarousel-control-next {
        right: 20px;
      }

      &.jcarousel-control-prev {
        left: 20px;
      }
    }
  }
}
