// Normalize Styles
// @import 'node_modules/normalize.css/normalize';

// Box sizing partial styles:
// Apply a natural box layout model to all elements

@import '_mixins.scss';
@import '_helpers.scss';
@import '_variables.scss';

@import 'node_modules/slick-carousel/slick/slick.scss';

@import '_flexslider_img.scss';
@import '_flexslider.scss';
@import '_superfish';
@import '_bootstrap';
@import '_overrides';
@import '_pmo.scss';
@import '_icons.scss';
@import '_icomoon.scss';
@import '_print.scss';

@import url('//fonts.googleapis.com/css?family=Roboto:400,500,700');
@import url('//fonts.googleapis.com/css?family=Cardo:700,400italic');

@import '../_modules/slick-carousel/slick-carousel';
@import '../_modules/magnific-popup/magnific-popup';
@import '../_modules/combo-select/combo-select';
@import '../_modules/qtip/qtip';
@import '../_modules/header-highlighter/header-highlighter';

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  word-wrap: break-word;

  &.js.load {
  opacity: 0;
  }

  &.js.loaded {
    opacity: 1;
    transition: all .2s;
  }
}

.clear{
    clear: both;
}

::-webkit-input-placeholder {
  color: #000;
}
:-moz-placeholder {
  color: #000;
  opacity: 1;
}
::-moz-placeholder {
  color: #000;
}
:-ms-input-placeholder {
  color: #000;
}

%headerFont {
  font-family: $headerFont;
}

%bodyFont {
  font-family: $bodyFont;
}

%grayscaleLink {
  color: #777;

  &:hover {
    color: #999;
  }
}

body {
  background: #FDFBF7;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;

  &.notransform {
    transform: translate(0px, 0px) !important;
  }

  h2.italic-title {
    font-style: italic;
  }

  h3.subtitle {
    color: #aaa;
    font-style: italic;
  }

  div.special-container {
    padding: 5px 10px;
    background: #eee;
    border: 1px solid #ccc;
  }

  span.rtl {
    direction: rtl;
  }

  span.ltr {
    direction: ltr;
  }

  img.right {
    border: 1px solid #ccc;
    float: right;
    margin-left: 15px;
    padding: 5px;
  }

  img.left {
    border: 1px solid #ccc;
    float: left;
    margin-right: 15px;
    padding: 5px;
  }

  ul.square-bulleted-list {
    list-style-type: square;
  }

  table {
    margin: 0 0 1em;

    p {
      margin: 0 !important;
    }

    td, th {
      vertical-align: top;
      padding: .25em .5em;
      border: solid 1px #ccc;
    }

    &.table-no-border {
      td, th {
        border: solid 1px transparent; padding-left: 0;
      }
    }

    &.table-thin-style {
      border: 1px solid #000;
      width: 500px;
    }

    &.table-medium-style {
      border: 3px solid #000;
      width: 500px;
    }

    &.table-thick-style{
      border: 5px solid #000;
      width: 500px;
    }
  }

  span.marker {
    background-color: yellow;
  }
}

.field-body {
  p:last-child, p {
    margin: 10px 0 30px;
  }
}

.columns3{

  ul{
    padding: 0;
    display: block;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 0;

     li {
      float: left;
      width: 100%;
      display: block;
      min-height: 400px;

      a {
        display: inline-block;
        text-align: center;
      }
    }
  }
}

@media (min-width: $bpTablet) {
  .columns3 {
    ul{
      margin-top: -125px;

      li {
        width: 33.33333333%;

        div {
          margin: 1em 0;
        }

        a img {
          margin: auto;
          border: solid 1px #ddd;
        }
      }
    }
  }
}

em.caption {
  font-style: italic;
}

em.rteCaption {
  font-style: italic;
  color: #333;
}
em.rteCaptionP {
  font-size: 16px;
}

em.summaryCaption {
  font-style: italic;
  color: #333;
  font-size: 20px;
}

.element-invisible {
  display:none;
}

.anchor {
  margin-top: -150px;
  padding-top: 150px;
}

#base-content {
  background-image: url("../images/footer-pattern.png") !important;
  background-repeat: no-repeat !important;
  background-position: center bottom !important;
}

#block-system-main footer {
  background:none;
  border:0px;
  margin:auto;
}

.bg {
  background-image: url("../images/footer-pattern.png");
  background-repeat: no-repeat;
  background-position: center bottom;
}

a {
  &, &:visited, &:focus {
    color: $red;
  }

  &:hover, &:active {
    text-decoration: none;
    color: $hoverRed;
  }

  &:focus, &:active:focus {
    text-decoration: none;
  }
}

input[type=text]::-ms-clear,
input[type=text]::-ms-reveal {
  display: none;
}
select::-ms-clear {
  width : 0;
  height: 0;
  display: none;
}
select::-ms-reveal {
   display: none;
}

textarea:focus, input:focus, .uneditable-input:focus {
  border-color: rgba(178, 0, 0, 0.8) !important;
  box-shadow: 0 1px 1px rgba(229, 103, 23, 0) inset, 0 0 8px rgba(229, 103, 23, 0) !important;
  outline: 0 none !important;
}

button:focus {
  border-color: rgba(178, 0, 0, 0) !important;
  box-shadow: 0 1px 1px rgba(229, 103, 23, 0) inset, 0 0 8px rgba(229, 103, 23, 0) !important;
  outline: 0 none !important;
}

blockquote {
  border-left: 5px solid rgba(178, 0, 0, 0.3);
}

img {
  max-width: 100%;
}

em {
  color: #b20000;
  font-style: normal;
}

aside {
  margin-top: 40px;
}

@media only screen and (min-width: $bpTablet) {
  aside {
    margin-top: 0;
  }
}

/******
Drupal css overwrite
******/
.no_org {
  display:none;
}

.front .node-body {
  display:none;
}

#total_1{
  display:none;
}

/******
Bootstrap.css overwrite
******/

.navbar {
  margin-bottom: 0px;
}

.btn-primary {
  color: #fff;
  background-color: $hoverRed;
  border-color: $hoverRed;

  &:hover, &:focus {
    border-color: darken($hoverRed, 10%);
    background-color: darken($hoverRed, 10%);
  }
}

.btn-info {
  width: 100%;
  color: #fff;
  background-color: $hoverRed;
  border-color: $hoverRed;

  &:active, &:hover {
    color: #fff;
    background-color: $red;
    border-color: $red;
  }
}

.form-required { color: #f00; }

.form-control {
  outline: 0;
  outline-style: none;
  outline-width: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

.italicise {
  font-style: italic;
}

/*------------------------------------*  titles..
 * \*------------------------------------*/
h1, .alpha, h2, .beta, h3, .gamma, article h4, article h5, .sidebar-title, .video-links {
  @extend %headerFont;
}

h1, .alpha {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (min-width: $bpTablet) {
  h1, .alpha {
    font-size: 40px;
  }
}

h2, .beta {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 20px;
}

h3, .gamma {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  margin: 20px 0;
}

h4, .delta, .aside-title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
}

h5, .epsilon {
  text-transform: uppercase;
  color: #444;
  font-size: 12px;
  line-height: 1.2;
}

h6 {
  line-height: 1.2;
}

article {
  h4 {
    font-size: 18px;
    font-weight: 700;
  }

  h5 {
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
  }

  h6 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
  }
}


[class^="col-"], [class*=" col-"] {
  p.gamma:first-child {
    margin-top: 0;
  }
}

.nav-tabs {
  font-size: 80%;
}

.sidebar-title {
  font-weight: 700;
  margin: 10px 0;
}

.title-line {
  border-bottom: 1px solid rgba(178, 0, 0, 0.3);
  padding-bottom: 16px;
  margin-bottom: 20px;
}

.summary {
  font-size: 20px;
  margin-bottom: 30px !important;

  .front & {
    @extend %vh;
  }
}


.inline-component {
  margin-top: 40px;
}

.share-icons {
  margin-top: 24px;

  a {
    font-size: 32px;
    margin-right: 34px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.event-table {
  font-size: 14px;
  color: #565656;
  margin: 20px 0;

  .tb-label {
    width: 84px;
    vertical-align: top;
  }
}

.pg-section {
  // margin-top: 20px;

  #block-system-main & {
    margin-top: 20px;
  }
}

@media only screen and (min-width: $bpTablet) {
  .pg-section {
    margin-top: 100px;

    .not-front & {
      margin-top: 0;
    }

    #block-system-main & {
      margin-top: 100px;
    }
  }

  .pg-section-divders {
    &.pg-section {
      margin-top: 100px;
    }

    .row > div {
      border-right: 1px dotted rgba(178, 0, 0, 0.3);

      &:last-child {
        border-right: none;
      }
    }
  }
}

.rc-with-thumb {
  @extend %clearfix;

  .rc-thumb img {
    width: 100%;
  }
}

@media only screen and (min-width: $bpTablet) {
  .rc-with-thumb {
    div {
      float: right;
    }

    .rc-title {
      width: 60%;
    }
    .rc-thumb {
      width: 40%;
      margin-top: 0;
    }
    img {
      max-width: 100%;
    }
  }
}

.div-line {
  border-top: 1px solid rgba(178, 0, 0, 0.3);
  margin-bottom: 50px;
  margin-top: 20px;
  &.style-2 {
      margin-top: 40px;
      margin-bottom: 40px;
  }
}

.sidebar {
  border-top: 4px solid $red;
  margin-bottom: 40px;
}

.yt-container {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);

  img {
    width: 100%;
  }

  .yt-sub-container {
    padding: 10px 10px 5px;
    background: #FFF;
  }
}

.video-links {
  display: inline-block;
  margin-top: 20px;
  width: 100%;
  position: relative;

  .front & {
    .field-thumbnail {
      padding-bottom: 56.32%;
    }
  }

  .field-thumbnail {
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &--related {
    .field-thumbnail {
      padding-bottom: 56.39%;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  &:visited {
    color: $visitedLink;
  }

  p {
    margin-top: 10px;
  }
}

.profile-links {
  margin-top: 20px;

  a {
    display: block;
    margin-top: 10px;
  }
}

.dbl-space {
  p, ol, ul {
    margin: 10px 0 30px;
  }
}

.region-highlighted .div-line{
    margin-bottom: 0px!important;
}

.meta-data, .notes .note .note-date {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  color: #565656;

  span {
    text-transform: uppercase;
  }
}

.meta-table {
  font-size: 14px;
  margin-bottom: 10px;
  color: #565656;
}

.btn-pmo {
  display: inline-block;
  color: #FFF !important;
  padding: 8px 14px;
  border-radius: 3px;
  margin-bottom: 10px;
  background: $red;
  font-size: 14px;
  transition: background .3s;
  margin-left: 4px;
  margin-right: 4px;

  &:hover {
    background: $hoverRed;
  }
}

.btn-view-all {
  text-align: center;
  margin: 40px 0;
}

.personnel-grid {
  min-height: 330px;

  img {
    margin-bottom: 10px;
  }

  a {
    display: block;
  }
}

.btn-pill {
  display: inline-block;
  color: $red;
  padding: 4px 10px;
  border: 1px solid $red;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 14px;
  transition: background .3s;

  &.pill-active {
    border: 1px solid $hoverRed;
    background: $hoverRed;
    color: #ffffff;
  }

  &:last-child {
    margin-right: 0;
  }

  .no-js &:hover,.no-js &:focus, .no-touch &:hover, .js.touch &:active {
    border: 1px solid $hoverRed;
    background: $hoverRed;
    color: #ffffff;
  }
}

.pagination-container {
  text-align: center;
  margin: 40px 0 0;

  .pagination {
    margin-bottom: 0;
  }

  a {
    margin: 0 6px;
  }

  .pagination-disabled {
    color: #565656;
  }
}

.aside-widget {
  margin-bottom: 20px;

  &.tags ul {
    padding: 0;
    list-style: none;
  }
  &.tags li a {
    display: inline-block;
  }
  &.tags a {
    color: $red;
  }
}

.aside-title {
  font-weight: 700;
  border-bottom: 1px solid rgba(178, 0, 0, 0.3);
  padding-bottom: 10px;
}

@media only screen and (min-width: $bpTablet) {
  .form-control.field-getupdates {
    width: 100%;
  }
}

// .btn-getupdates, .btn-subscribe {
//   background: $red;
//   color: #FFF;
//   border-color: transparent;
//   margin-left: 0;
//   float: right;

//   &:hover, &:active, &:focus {
//     background: $hoverRed;
//     color: #FFF;
//     border-color: transparent;
//   }

//   &:active:focus {
//     background: $hoverRed;
//     color: #FFF;
//     border-color: transparent;
//   }
// }


// @media only screen and (min-width: $bpTablet) {
//   .btn-getupdates, .btn-subscribe {
//     margin: 8px 0 0 0px;
//   }
// }

.tag-label {
  display: block;
  font-size: 14px;
  color: $hoverRed;
}

.simple-list {
  @extend %list-unstyled;

  li {
    display: block;
    margin-bottom: 6px;
    float: none;
  }
}

#pmo-office-map {
  width: 100%;
  height: 320px;
}

.align-center {
  text-align: center;
}

.content-block {
  margin-bottom: 40px;
}

@media only screen and (min-width: $bpTablet) {
  .content-block {
    margin-bottom: 0;
  }
}

nav.mobile-tabs {
  margin-bottom: 20px;
  text-align: center;

  a {
    width: 40%;
    text-align: center;
  }
}

.topic-listing {
  margin: 40px 0;
  font-size: 14px;
}

.bottom-pad {
  padding-bottom: 20px;
}

.search-inputbox .form-control {
  width: 100%;
  padding: 6px 2px;
}

.p404-top {
  margin-top: 100px;
}

.p404-bottom {
  margin-bottom: 400px;
}

.dialogue-notes {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// .subscribe {
//   position: relative;

//   input {
//     padding-left: 30px;
//     margin-bottom: 20px;
//     width: 100%;
//   }

//   .ico-email {
//     position: absolute;
//     font-size: 20px;
//     left: 0;
//     top: 4px;
//   }
// }

// @media only screen and (min-width: $bpTablet) {
//   .subscribe {
//     input {
//       margin-bottom: 0;
//     }
//   }

//   .btn-subscribe {
//     margin: 0 0 0 8px;
//   }
// }

.photo-stream {
  overflow: scroll;
  height: 600px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;

  .ps-caption {
    background: #FFF;
    font-size: 14px;
    padding: 20px;
  }
}

/*------------------------------------*  back to top..
 * \*------------------------------------*/
.btt {
  position: fixed;
  bottom: 10px;
  right: 20px;
  text-align: center;
  color: #FFF;
  opacity: 0;
  transition: opacity .3s;

  .arrow-icon {
    transition: opacity .3s;
    width: 50px;
    height: 50px;
    background: #565656;
    border-radius: 50%;
    opacity: 0.8;
  }

  .btt-label {
    position: absolute;
    margin: 0;
    bottom: 8px;
    left: 14px;
  }

  &:visited, &:focus {
    color: #FFF;
  }

  p {
    margin-top: 5px;
    font-size: 12px;
    text-transform: uppercase;
  }

  &.show {
    opacity: 1;
  }

  &:hover {
    color: #FFF;
    .arrow-icon {
      background: $hoverRed;
      opacity: 1;
    }
  }
}

/*------------------------------------*  profile tab..
 * \*------------------------------------*/
.profile-tab {
  border-bottom: 1px solid rgba(178, 0, 0, 0.3);
  padding-bottom: 10px;
  margin-bottom: 10px;

  a {
    color: #000;
    margin-right: 14px;

    &.active {
      border-bottom: 2px solid;
      color: $red;
      padding-bottom: 10px;
    }
  }
}

.tab-content {
  display: none;
}

.biodata-table {
  margin-bottom: 20px;
  font-size: 85%;

  &, th, td, tr {
    border:none;
  }

  p {
    margin-bottom: 4px;
  }

  th {
    display: block;
    margin-bottom: 10px;
  }

  tr {
    vertical-align: top;
  }

  .year {
    width: 20%;
  }
}

/*------------------------------------*  dialogue..
 * \*------------------------------------*/
.dialogue-chunk {
  position: relative;
  margin-bottom: 40px;

  p {
    margin-bottom: 40px;
  }

  .profile-pic {
    position: absolute;
    width: 50px;

    img {
      border-radius: 50%;
    }
  }

  .dialogue-piece {
    margin-left: 60px;
    padding-top: 10px;
  }
}

.dialogue-img {
  margin-bottom: 40px;

  img {
    width: 100%;
  }
}

.caption {
  font-size: 14px;
  color: #565656;
  margin: 8px 0;
}

.dialogue-end {
  img {
    margin: 0 auto;
    width: 70px;
    display: block;
    position: relative;
    z-index: 2;
  }

  .endline {
    position: relative;
    top: -16px;
    border-bottom: 1px solid rgba(178, 0, 0, 0.3);
  }
}

.dialogue-share {
  margin: 40px 0;
  text-align: center;
}

.dialogue-notes {
  li {
    font-size: 14px;
    color: #565656;
    margin-bottom: 10px;
  }

  a {
    color: $red;
  }
}

@media only screen and (min-width: $bpTablet) {
  .dialogue-chunk {
    .profile-pic {
      left: -60px;
    }

    .dialogue-piece {
      margin-left: 0;
    }
  }
}

/*------------------------------------*  notes..
 * \*------------------------------------*/
.featured-notes {
  position: relative;
  margin-bottom: 40px;
  background: #FFF;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);

  img {
    width: 100%;
  }

  .dimmer-cover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    background: linear-gradient(transparent 40%, rgba(0, 0, 0, 0.8));
    z-index: 2;
    pointer-events: none;
  }

  .featured-content {
    -webkit-font-smoothing: antialiased;
    padding: 15px;

    h2 {
      margin-top: 0px;
      margin-bottom: 10px;
    }

    a {
      color: $red;

      &:hover {
        color: $hoverRed;
        cursor: pointer;
      }
    }

    .tags {
      a {
        color: #565656;

        &:hover {
          color: $hoverRed;
        }
      }
    }
  }
}

@media only screen and (min-width: $bpTablet) {
  .featured-notes {
    background: transparent;
    box-shadow: none;

    .featured-content {
      /*pointer-events: none;*/
      bottom: 40px;
      padding: 0 100px;
      text-align: center;
      position: absolute;
      bottom: 20px;
      color: #FFF;
      z-index: 9;

      a {
        color: #FFF;

        &:hover {
          color: #FFF;
        }
      }

      .meta-data, .notes .note .note-date, .notes .note & .note-date {
        color: #FFF;
      }

      .tags {
        a {
          color: #FFF;

          &:hover {
            color: #FFF;
          }
        }
      }
    }
  }
}

.notes .note {
  @extend %clearfix;
  border-bottom: 1px solid rgba(178, 0, 0, 0.3);
  padding-bottom: 40px;
  margin-bottom: 20px;

  > div {
    float: right;
  }
  .note-date {
    width: 100%;
    padding: 14px 0 0 0;
  }
  .note-desc {
    width: 100%;
  }

  &:last-child {
    border-bottom: none;
  }
}

@media only screen and (min-width: $bpTablet) {
  .notes .note {
    .note-date {
      width: 25%;
      padding: 20px 20px 0 0;
    }

    .note-desc {
      width: 75%;
    }
  }
}

.tags {
  font-size: 14px;

  a {
    color: #565656;

    &:hover {
      color: $hoverRed;
    }
  }
}

/*------------------------------------*  about PMO..
 * \*------------------------------------*/
.sub-sections {
  text-align: center;
  margin-bottom: 40px;

  a {
    display: block;
    margin: 0 10px;
    max-width: 60%;
    margin-bottom: 20px;
    text-align: center;
    margin: 0 auto 20px;
  }
}

@media only screen and (max-width: $bpDesktop - 1) {
  #base-content {
    background-position: center bottom 250px !important;
  }
}

@media only screen and (min-width: $bpTablet) {
  .sub-sections {
    a {
      margin: 0 10px;
      display: inline-block;
    }
  }
}


@import '../_modules/atoms/nda-medal/nda-medal';
@import '../_modules/atoms/section-title/section-title';

@import '../_modules/molecules/accordion/accordion';
@import '../_modules/molecules/pmo-chart/pmo-chart';
@import '../_modules/molecules/social-share/social-share';
@import '../_modules/molecules/site-tool/site-tool';
@import '../_modules/molecules/breadcrumbs/breadcrumbs';
@import '../_modules/molecules/nda-search/nda-search';
@import '../_modules/molecules/topic-filters/topic-filters';
@import '../_modules/molecules/quote-box/quote-box';
@import '../_modules/molecules/cabinet-member/cabinet-member';
@import '../_modules/molecules/hero-banner/hero-banner';
@import '../_modules/molecules/facet-group/facet-group';
@import '../_modules/molecules/inner-search/inner-search';
@import '../_modules/molecules/topic-card/topic-card';
@import '../_modules/molecules/pagination/pagination';
@import '../_modules/molecules/photo-slider/photo-slider';
@import '../_modules/molecules/snippet/snippet';
@import '../_modules/molecules/subscribe-form/subscribe-form';
@import '../_modules/molecules/related-list/related-list';
@import '../_modules/molecules/rte/rte';
@import '../_modules/molecules/photo-card/photo-card';
@import '../_modules/molecules/topic-item/topic-item';

@import '../_modules/organisms/header/header';
@import '../_modules/organisms/notification/notification';
@import '../_modules/organisms/page-header/page-header';
@import '../_modules/organisms/home-carousel/home-carousel';
@import '../_modules/organisms/home-quote/home-quote';
@import '../_modules/organisms/stay-connected/stay-connected';
@import '../_modules/organisms/multislide/multislide';
@import '../_modules/organisms/photo-aside/photo-aside';
@import '../_modules/organisms/topics-list/topics-list';
@import '../_modules/organisms/sitemap/sitemap';
@import '../_modules/organisms/home-newsroom/home-newsroom';
@import '../_modules/organisms/read-more/read-more';
@import '../_modules/organisms/photo-gallery/photo-gallery';
@import '../_modules/organisms/serp-container/serp-container';
@import '../_modules/organisms/facets-container/facets-container';
@import '../_modules/organisms/results-container/results-container';
@import '../_modules/organisms/nda-awards/nda-awards';
@import '../_modules/organisms/footer/footer';
@import '../_modules/organisms/search-results/search-results';
