// topic-item styles
.topic-item {
  .ti-content {
    line-height: 1;

    a {
      &:visited {
        color: $visitedLink;
      }
    }
  }
}
