// photo-slider styles
#photothumb, #flexslider-2 {
  ul.slides {
    li {
      margin-right: 5px;
    }
  }
}

.custom-flexi .flex-viewport {
    margin: 0 38px;
}

.flex-pauseplay a {
  display:none;
}

#flexslider-1 {
  .field-thumbnail {
    padding-bottom: 71%;
  }
}

#photothumb {
  .field-thumbnail {
    padding-bottom: 67.5%;
  }
}

.flexslider {
  background: transparent !important;
  border: 0px !important;
  box-shadow:none !important;

  .slides {
    li {
      position: relative;
    }

    img {
      // width:auto !important;
      width: 100%;
      height: 100%;
      margin:0 auto;
      position: absolute;
      object-fit: cover;
      object-position: center center;
    }
  }
}

a.flex-next {
  &, &:hover {
    background-image:url(../images/flex-next.png);
    text-indent:9999px;
    width:46px;
    height:42px;
    background-repeat:no-repeat;
  }
}

a.flex-prev {
  &, &:hover {
    background-image:url(../images/flex-prev.png);
    text-indent:9999px;
    width:46px;
    height:42px;
    background-repeat:no-repeat;
  }
}
