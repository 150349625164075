%vh {
    border: 0;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    position: absolute;
    background: none;
    text-indent: 999px;
}

%clearfix { @include clearfix(); }

%container {
    @extend %clearfix;
    margin: auto;
    max-width: 1024px;
    position: relative;
    width: 100%;
    padding: 0 20px;
}

%list-unstyled {
    @include normalise-list;
}
