// footer styles
footer {
  margin-top: 4px;
  border-top: 1px solid $red;
  background: #FDFBF7;

  .footer__top {
    @extend %clearfix;
    padding-bottom: 20px;
  }

  .footer__bottom {
    border-top: 1px solid #ccc;
  }

  .footer-links {
    font-size: 14px;
    
    ul {
      @extend %list-unstyled;

      li {
        margin-bottom: 10px;
      }
    }
  }

  .terms, .rate {
    margin: 15px 0;
    font-size: 14px;
    color: $gray;

    ul {
      @extend %list-unstyled;
      overflow: hidden;

      > li {
        display: block;
        margin-bottom: 10px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

@media only screen and (min-width: $bpDesktop) {
  footer {
    .rate {
      float: right;
    }

    .footer-links {
      float: right;

      ul {
        li {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }

    .terms {
      float: left;

      ul {
        li {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }
}
