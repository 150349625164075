// topic-card styles

.topics-content {
  border-top: 1px solid rgba(178, 0, 0, 0.3);
  padding-top: 10px;

  .item-count {
    font-size: 12px;
    color: #565656;
    margin-top: 4px;
  }
}

.col-height {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: $bpDesktop) {
  .col-height {
    height: 170px;
    margin-bottom: 0px;
  }
}
