// serp-container styles
.serp-container {
  @extend %clearfix;

  > div {
    float: left;
  }

  .facet-title {
    margin-bottom: 8px;
  }

  .lnk-clear-filters {
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .result-count {
    color: $gray;
  }

  .num-results, .selected-facets {
    margin-top: 20px;
    color: $gray;
  }

  .selected-facets {
    font-size: 14px;

    p {
      line-height: 2.5;
    }
  }

  .selected {
    background: $red;
    color: #FFF;
    border-radius: 14px;
    padding: 4px 7px 4px 10px;

    &::after {
      content: "\e912";
      font-family: 'pmo-icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      margin-left: 4px;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

@media only screen and (min-width: $bpTablet) {
  .serp-container {
    .selected-facets {
      p {
        line-height: 1.5;
      }
    }
  }
}
