// home-quote styles
.qicon {
  background-image: url('../images/icon-quote-no-bg.png');
  margin: auto;
  height: 80px;
  width: 80px;

  .grayscale & {
    background-image:url('../images/icon-quote-no-bg-grayscale.png');
  }
}

.home-quote-link {
  -webkit-font-smoothing: antialiased;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;

  .hq-link-title {
    @extend %headerFont;
    font-weight: 700;
  }
}

@media only screen and (min-width: $bpTablet) {
  .home-quote-link {
    margin-top: 40px;

    &.inverse {
      color: #FFF;
    }

    .meta-data, .notes .note .note-date, .notes .note & .note-date {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
