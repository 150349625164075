// page-header styles
.page-header {
  padding-bottom: inherit;
  margin: auto;
  border-bottom: 0px solid #eee;
  font-weight: 700;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 10px;

  .front & {
    display: none;
  }
}

.full-list-download {
  float: none;
  margin-bottom: 20px;
}

@media only screen and (min-width: $bpTablet) {
  .full-list-download {
    float: right;
    margin-bottom: 0px;
  }
}

