$bpMobile: 320px;
$bpLgMobile: 375px;
$bpTablet: 768px;
$bpDesktop: 992px;
$bpLgDesktop: 1200px;


$headerFont: 'Cardo', serif;
$bodyFont: 'Roboto', sans-serif;
$base-fontsize: 16px;


$red: #b20000;
$hoverRed: #7f0000;
$visitedLink: #5f2020;

$highlightYellow: #FFFEC9;

$gray: #565656;

$grayscaleDark: #666;
$grayscale: #777;
$grayscaleHover: #999;
