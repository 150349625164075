// read-more styles
.related-links a {
  @extend %headerFont;
  font-weight: 700;
  display: block;
  color: $red;
  margin: 0 0 20px;

  &:hover {
    color: $hoverRed;
  }

  .epsilon {
    margin-bottom: 0;
  }
}

