// inner-search styles
.inline-search {
  position: relative;

  input {
    background: #EFEBE3;
    border: none;
    width: 100%;
    height: 42px;
    line-height: 44px;
    padding-right: 40px;
    padding-left: 15px;
  }

  .ico-mag {
    position: absolute;
    right: 13px;
    top: 13px;
  }
}
