// photo-gallery styles
.photo-gallery {
  position: relative;
  display: inline-block;
  width: 100%;

  .photo-link {
    display: none;

    &:first-child {
      display: block;
    }
  }

  img {
    width: 100%;
  }

  .photo-container {
    position: relative;
    display: block;
  }

  .photo-count {
    pointer-events: none;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    padding: 6px 6px 0;

    span {
      color: #FFF;
      vertical-align: text-top;
    }
  }

  .photo-count-number {
    font-size: 12px;
    margin-left: 4px;
    -webkit-font-smoothing: antialiased;
  }

  .photo-launcher {
    pointer-events: none;
    display: none;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    padding: 10px;
    width: 180px;
    -webkit-font-smoothing: antialiased;
    left: 50%;
    margin-left: -90px;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .photo-cta {
      font-size: 14px;
      margin-left: 4px;
    }
  }

  &:hover {
    .photo-launcher {
      color: #FFF;
      display: block;
    }
  }
}
