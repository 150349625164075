// pmo-chart styles
.copy-space {
  margin-top: 40px;
}

.about-logo {
  margin-bottom: 30px;
  max-width: 250px;
}

.about-link {
  display: block;
  margin: -20px 0 40px 0;
}

.pmo-chart {
  width: 100%;
  margin-bottom: 30px;

  .pmo-circle {
    cursor: pointer;

    &:hover {
      fill: #7f0000;

      text {
        fill: #FFF;
      }

      circle {
        stroke: #7f0000;
      }
    }
  }
}
