/*------------------------------------*  notification..
 * \*------------------------------------*/
 .notif-msg .qna {
  margin-left: 0px;
  margin-right: 0px;
}

.notification-bar {
  display: none;
  background: #FCEDAC;
  padding-top: 22px;
  padding-bottom: 12px;

  .notification-content {
    position: relative;
  }

  .icon-info {
    position: absolute;
    top: 2px;
    left: 0;
    font-size: 16px;
  }

  .notif-msg {
    font-size: 16px;
    margin-left: 32px;
    margin-right: 26px;
  }

  .notif-close {
    position: absolute;
    top: 0;
    right: 0;
    color: #000;
    display: inline-block;
  }
}
