@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9i1som');
  src:  url('../fonts/icomoon.eot?9i1som#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9i1som') format('truetype'),
    url('../fonts/icomoon.woff?9i1som') format('woff'),
    url('../fonts/icomoon.svg?9i1som#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon2-"], [class*=" icon2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon2-sg-crest:before {
  content: "\e948";
}
.icon2-telegram:before {
  content: "\e900";
}
