// topic-filters styles

.topic-explorer, .topic-filters {
  width: 100%;
  background: none;
  z-index: 60;
  padding-top: 20px;

  .topic-explorer-fixed {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);

    .topic-selector {
      margin-bottom: 10px;
    }
  }
}

.topic-selector-nav {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  display: none;
}

.topic-selector-progress {
  display: inline-block;
  float: left;
  margin-left: 4px;
  margin-right: 0;

  p {
    margin-bottom: 0;
  }
}

.topic-selector {
  text-align: center;
  margin-bottom: 40px;
  color: #565656;

  p {
    margin-bottom: 20px;
    font-size: 14px;
  }

   .combo-select {
    max-width: 100%;
  }
}

.topic-selector-progress, .topic-selector-controls, .topic-selector-clear {
  display: inline-block;
}

.topic-selector-clear {
  float: right;
  margin-right: 4px;
}

@media only screen and (min-width: $bpTablet) {
  .topic-selector-progress {
    float: none;
    margin-left: 0;
    margin-right: 20px;
  }
}


