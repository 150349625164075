// social-share styles
.share-total {
  p:last-child, p {
    margin: 0 0 10px;
  }
}

.social-share {
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  margin: 40px 0 30px 0;

  .node-type-transcript & {
    a {
      float:inherit !important;
      display:inline-block;
    }
  }

  .ico-share {
    margin-right: 0;
    font-size: 20px;
    position: relative;
    top: 1px;
    vertical-align: text-bottom;
  }

  .share-txt {
    display: inline-block;
    text-align: left;
    padding-left: 4px;
    padding-bottom: 2px;
    min-width: 0;
    display: none;
  }

  .share-count {
    float: right;
    position: relative;
    top: 3px;
  }

  .share-total {
    color: $gray;
    display: inline-block;
  }

  a {
    text-transform: uppercase;
    margin-bottom: 10px;
    transition: all .3s ease;
    padding: 5px 6px;
    border-radius: 3px;
    color: #FFF;
    margin: 0;
    margin-right: 4px;
    display: block;
    width: 2pc;
    height: 2pc;
    float: left;

    svg {
      width: 32px;
      height: 32px;
      fill: #fff;
      margin: -5px -6px;
    }

    &:hover {
      margin-top: -4px;
    }

    &.facebook {
      background: #3C5A97;

      &:hover {
        background: #2d4473;
      }
    }

    &.twitter {
      background: #2696DD;

      &:hover {
        background: #1c79b4;
      }
    }

    &.email {
      background: #727272;

      &:hover {
        background: #595959;
      }
    }

    &.whatsapp {
      background: #4DC247;
    }

    &.email {
      .ico-share {
        top: -1px;
      }
    }
  }



  .grayscale & {
    a {
      &.facebook {
        background: $grayscaleHover;

        &:hover {
          background: $grayscaleDark;
        }
      }

      &.twitter {
        background: $grayscaleHover;

        &:hover {
          background: $grayscaleDark;
        }
      }

      &.email {
        background: $grayscaleHover;

        &:hover {
          background: $grayscaleDark;
        }
      }

      &.whatsapp {
        background: $grayscaleHover;

        &:hover {
          background: $grayscaleDark;
        }
      }
    }
  }
}

.share-component {
  position: relative;

  .icon-wifi::before {
    display: block;
    margin-right: 1px;
    margin-top: -3px;
  }
}

@media only screen and (min-width: $bpTablet) {
  .social-share {
    .share-count {
      float: none;
      top: 0;
    }

    a {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
}

@media only screen and (min-width: $bpDesktop) {
  .social-share {
    margin: 40px 0 30px 0;
  }
}
