// snippet styles
.snippet {
  @extend %clearfix;
  border-bottom: 1px solid rgba(178, 0, 0, 0.3);
  padding-bottom: 30px;
  margin-bottom: 20px;

  .grayscale & {
    a {
      &:visited {
        color: $grayscale;
      }

      &:hover {
        color: $grayscaleHover;
      }
    }
  }

  a {
    &:visited {
      color: $visitedLink;
    }
  }

  > div {
    float: right;
  }

  .snippet-content {
    width: 100%;
    padding-right: 15px;

    h2 {
      margin-bottom: 8px;
    }

    &.full-width {
      width: 100%;
    }
  }

  .snippet-img {
    overflow: hidden;
    padding-bottom: 56.14%;
    position: relative;
    width: 100%;

    img {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .snippet-tags {
    width: 100%;
    float: left;
    margin-top: 20px;

    a {
      &:visited {
        color: $red;
      }

      &:hover {
        color: $hoverRed;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .snippet {
    .snippet-content {
      width: 66.666%;
    }

    .snippet-img {
      margin-top: 20px;
      width: 33.333%;
      padding-bottom: 18.71%;
    }
  }
}
