// photo-card styles
.photo-card {
  .grayscale & {
    a {
      &:visited {
        color: $grayscale;
      }

      &:hover {
        color: $grayscaleHover;
      }
    }
  }

  a {
    &:visited {
      color: $visitedLink;
    }
  }
}
