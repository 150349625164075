// home-carousel styles
.slick-carousel-home {
  z-index: 15
}

.view-homepage-slideshow {
  overflow: hidden;
  position: relative;
}

.slider-indicators {
  position: absolute;
  bottom: 30px;
  width: 135px;
  z-index: 999;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align:center;
}

.carousel-indicators {
  position: static;
  margin-bottom: -24px;
  margin-left: 26px;
  z-index: 999;

  .active {
    background-color: $red;
    border-color: $red;
  }

  li{
    width: 15px;
    height: 15px;
  }

  li.active {
    width: 15px;
    height: 15px;
  }
}

.home-banner-links {
  a {
    @extend %headerFont;
    color: #000;
    display: inline-block;
    margin-top: 10px;

    &:hover, &.active {
      color: $red;
    }
  }

  .grayscale & {
    a {
      &:hover, &.active {
        color: $grayscaleHover;
      }
    }
  }
}

.jcarousel-wrapper {
  position: relative;
}

.jcarousel {
  position: relative;
  overflow: hidden;

  ul {
    @extend %list-unstyled;
    width: 20000em;
    position: relative;
  }

  li {
    float: left;
  }
}

.jcarousel-control {
  position: absolute;
  top: 44%;
  background: rgba(0, 0, 0, 0.3);
  padding: 12px;
  border-radius: 20px;

  &::before, &::after {
    color: #FFF;
  }

  &:hover {
    background: $red;
  }

  &.jcarousel-control-next, &.jcarousel-control-prev {
    box-shadow: 0 0 2px #fff;
  }

  &.jcarousel-control-next {
    right: 0;
  }

  &.jcarousel-control-prev {
    left: 0;
  }
}

@media only screen and (max-width: $bpTablet - 1) {
  .slider-indicators {
    bottom: auto;
  }
}

@media only screen and (min-width: $bpTablet) {
  .jcarousel-control {
    &.jcarousel-control-next {
      right: -50px;
    }

    &.jcarousel-control-prev {
      left: -50px;
    }
  }
}
