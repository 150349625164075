// facet-group styles
.lnk-show-more {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: none;
}

.facets {
  display: none;
}

.facet-category {
  margin-bottom: 40px;

  ul {
    font-size: 14px;
  }
}


@media only screen and (min-width: $bpDesktop) {
  .facets {
    display: block;
  }
}
