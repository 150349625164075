// subscribe-form styles
.btn-getupdates, .btn-subscribe {
  background: $red;
  color: #FFF;
  border-color: transparent;
  margin-left: 0;
  float: right;

  &:hover, &:active, &:focus {
    background: $hoverRed;
    color: #FFF;
    border-color: transparent;
  }

  &:active:focus {
    background: $hoverRed;
    color: #FFF;
    border-color: transparent;
  }
}

.subscribe {
  position: relative;

  .control-label {
    @extend %vh;
  }

  .form-control {
    padding-left: 30px;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #000;
    border-radius: 0;
    box-shadow: none;
  }

  .ico-email {
    position: absolute;
    font-size: 20px;
    left: 0;
    top: 4px;
  }

  .scfEmailBorder {
    &:before {
      font-family: 'pmo-icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e902";
      position: absolute;
      font-size: 20px;
      left: 0;
      top: 4px;
    }

    .form-control {
      padding: 6px 12px 6px 30px;
    }

    .help-block {
      display: none;
    }
  }

  .form-submit-border {
    float: right;

    .btn-default {
      color: #fff;
      background-color: $red;
      border-color: transparent;

      &:hover, &:focus {
        background: $hoverRed;
        border-color: transparent;
      }
    }
  }

  .scfSingleLineTextBorder {
    display: none !important;
  }
}

@media only screen and (min-width: $bpTablet) {
  .btn-getupdates {
    margin: 8px 0 0 0;
  }

  .subscribe {
    input {
      margin-bottom: 0;
    }

    .form-submit-border {
      display: inline-block;
      float: none;
    }

    .btn-default {
      margin: 0 0 0 8px;
    }
  }

  .btn-subscribe {
    margin: 0 0 0 8px;
  }
}
