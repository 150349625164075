// quote-box styles
.qna section.quotebox p{
    width: 80%;
}

.qshare2 {
    background: #fdfbf7 none repeat scroll 0 0;
    margin: 0 auto;
    position: relative;
    width: 58px;
    z-index: 3;
}

.quotebox {
  .quote {
    @extend %headerFont;
    font-size:27px !important;
    font-style: oblique;
    font-size: 30px;
    margin-top: 10px;
  }

   p {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    -webkit-font-smoothing: antialiased;
  }

  .quote-by {
    margin-top: 10px;
    color: #999;
  }

  .qline-top {
    img {
      margin: 0 auto;
      width: 120px;
      display: block;
      position: relative;
      z-index: 2;
    }

    .qline {
      position: relative;
      top: -40px;
      border-bottom: 1px solid rgba(178, 0, 0, 0.3);
    }
  }

  .qline-bottom {
    margin: 20px 0;

    .qshare {
      position: relative;
      background: #FDFBF7;
      width: 100px;
      margin: 0 auto;
      z-index: 3;
    }

    a {
      font-size: 20px;
      color: #b20000;
      margin-left: 20px;
    }

    .qline {
      position: relative;
      top: -15px;
      border-bottom: 1px solid rgba(178, 0, 0, 0.3);
    }
  }

  &.inverse {
    .qline-top {
      img {
        width: 80px;
      }

      .qline {
        border-bottom: none;
      }
    }

    .quote, .quote-by {
      color: #565656;
    }

    .quote {
      line-height: 32px;
    }

    .qline-bottom {
      .qshare {
        background: none;
      }

      .qline {
        border-bottom: 1px solid rgba(86, 86, 86, 0.5);
        top: 10px;
      }
    }
  }
}


@media only screen and (min-width: $bpTablet) {
  .quotebox {
    p {
      width: 80%;
    }

    &.inverse {
      .quote, .quote-by {
        color: #FFF;
      }

      .quote {
        line-height: normal;
      }

      .qline-bottom {
        a {
          color: #FFF;
        }

        .qline {
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
