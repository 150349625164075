// topics-list styles
.topics-container {
  .ti-quote {
    @extend %headerFont;
  }

  .ti-quote-icon {
    background-image:url('../images/icon-quote-no-bg.png');
    display: block;
    margin: 10px auto;
    height: 50px;
    width: 50px;
    background-size: 50px;
  }

  .grayscale & {
    .ti-quote-icon {
      background-image:url('../images/icon-quote-no-bg-grayscale.png');
    }

    .content-type {
      .ct-bar {
        border-bottom: 4px solid #ccc;
      }
    }
  }
}

.topics-container {
  @extend %clearfix;

  .grid-sizer {
    width: 100%;
  }

  .topic-item {
    background: #FFF;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: $bpTablet) {
  .topics-container {
    .grid-sizer {
      width: 345px;
    }

    .topic-item {
      width: 345px;
      float: left;
    }
  }
}

@media only screen and (min-width: $bpDesktop) {
  .topics-container {
    .grid-sizer {
      width: 293px;
    }

    .topic-item {
      width: 293px;
      float: left;
    }
  }
}

@media only screen and (min-width: $bpLgDesktop) {
  .topics-container {
    .grid-sizer {
      width: 262px;
    }

    .topic-item {
      width: 262px;
      float: left;
    }
  }
}

.topics-container {
  .ti-masthead {
    width: 100%;
  }

  .ti-content {
    padding: 0px 15px;
  }

  .ti-date {
    font-size: 12px;
    color: #565656;
    margin-bottom: 20px;
  }

  // .ti-quote-icon {
  //   width: 70px;
  //   display: block;
  //   margin: 10px auto;
  // }
  .ti-quote {
    font-style: oblique;
    font-size: 26px;
    margin-top: 10px;
    text-align: center;
    padding: 0 10px;
  }
  .share-icons {
    text-align: center;

    a {
      font-size: 20px;
      margin-right: 20px;
    }
  }

  .ti-author {
    text-align: center;
    color: #999;
    font-size: 12px;
    font-style: italic;
  }

  .content-type {
    margin: 16px 0;
    position: relative;

    .ct-title {
      text-transform: uppercase;
      background: #FFF;
      display: inline-block;
      padding: 0 15px;
      position: relative;
      z-index: 2;
      font-size: 12px;
    }

    .ct-bar {
      border-bottom: 4px solid #b20000;
      position: absolute;
      top: 10px;
      width: 100%;
    }
  }
}

.topics-wrapper {
  margin-top: 40px;
}
