// breadcrumbs styles
.breadcrumbs {
  font-size: 14px;
  color: $gray;
  margin-top: 20px;
  margin-bottom: 20px;

  .front & {
    display: none;
  }

  a {
    color: $gray;

    &:hover {
      color: $hoverRed;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow: hidden;

    > li {
      display: block;
      float: left;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .crumb:last-child::after {
    content: '';
  }
}

.breadcrumb {
  padding: 0px;
  margin-bottom: inherit;
  list-style: none;
  background-color: transparent;
  border-radius: 0px;
}
