// header styles
// .mobile-search-btn {
//   background: transparent;
//   width: 40px;
//   height: 30px;
//   border: none;
// }

.sf-menu {
  z-index: 0;
}

.sg-gov-bar {
  background: #f0f0f0;
  font-size: 16px;

  .grayscale & {
    .icon2 {
      color: #777;
    }
  }

  a {
    color: #484848;
    font-size: 13px;

    &:hover {
      color: $red;
    }
  }

  .icon2 {
    font-size: 1.2em;
    color: #db0000;
    margin-right: 10px;
  }
}

.search-toggle {
  border: 0;
  cursor: pointer;
  background: none;
  padding: 7px 10px 5px;
  font-size: 16px;

  &:focus {
    background: lighten(#e0e0e0, 5%);
  }

  &.expanded {
    background: #e0e0e0;

    &:focus {
      background: #e0e0e0;
    }
  }
}

.header {
  // height: 110px;
  background: #FFF;

  .logo-container {
    display: inline-block;
  }

  .logo {
    display: inline-block;
    vertical-align: middle;
  }

  &__top {
    padding: 20px 0;
  }
}

.navbar {
  display: inline-block;
  vertical-align: middle;
  min-height: auto;
}

#superfish-1 {
  .grayscale & {
    .active-trail {
      .active {
        border-bottom: 2px solid #666;
      }
    }
  }
}

.navbar-toggle {
  margin: 0;

  .icon-bar {
    position: relative;
    background-color: #888;
  }

  .icon-bar:nth-child(2) {
    transform: rotate(45deg);
    top: 6px;
  }

  .icon-bar:nth-child(4) {
    transform: rotate(-45deg);
    top: -6px;
  }

  .icon-bar:nth-child(3) {
    opacity: 0;
  }

  &.collapsed {
    .icon-bar:nth-child(2) {
      transform: none;
      top: 0;
    }

    .icon-bar:nth-child(3) {
      opacity: 1;
    }

    .icon-bar:nth-child(4) {
      transform: none;
      top: 0;
    }
  }
}

.search-container, .mobile-search-container {
  display: none;
  width: 100%;
  background: #fff;
  padding: 20px;
  font-size: 16px;

  .grayscale & {
    .btn {
      background-color: #777;
      border-color: #777;
    }
  }

  form {
    position: relative;
    width: 100%;
  }

  input {
    width: 100%;
    outline: 0;
    padding: 6px 80px 6px 10px;
    border: 1px solid #bbb;
    font-size: 14px;

    &:focus {
      border-color: #555 !important;
    }
  }

  .btn {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
  }
}

@media only screen and (max-width: $bpDesktop - 1) {
  .header {
    .container {
      width: auto;
    }

    .search-toggle {
      position: absolute;
      right: 40px;
      top: 20px;
    }

    .navbar-toggle {
      position: absolute;
      right: 0;
      top: 20px;
    }

    .logo-container {
      padding-right: 60px;
    }
  }

  .navbar {
    position: absolute;
    display: block;
    margin-top: 20px;
    top: 100%;
    width: 100%;
    left: 0;
    background: #fff;
    z-index: 1000;
    border-radius: 0;

    ul {
      li {
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }
    }

    a {
      padding: 10px 0;
    }
  }

  #superfish-1 {
    .active-trail {
      .active{
          border-bottom: 2px solid #b20000;
      }
    }
  }

  #search-collapse {
    display: none !important;
  }

  .search-container {
    padding: 10px 0;
  }
}

@media only screen and (min-width: $bpTablet) {
  .navbar-toggle {
    display: inline-block;
  }

  .navbar-collapse.collapse {
    display: none !important;

    &.in {
      display: block !important;
    }
  }
}

@media only screen and (min-width: $bpDesktop) {
  .header {
    .search-toggle {
      float: right;
      margin-top: 20px;
    }
  }

  .navbar-toggle {
    display: none;
  }

  .navbar-collapse.collapse {
    display: block !important;
  }

  #superfish-1 {
    .active-trail {
      .active{
          border-bottom: 2px solid #b20000;
      }
    }
  }

  .mobile-search-container {
    display: none !important;
  }

  .search-container {
    position: absolute;
    // margin-top: 20px;
    transform: translateY(20px);
    z-index: 100;
    left: 0;
  }

  nav.main {
    font-size: 16px;

    ul {
      li {
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
}

@media only screen and (min-width: $bpLgDesktop) {
  nav.main {
    ul {
      li {
        margin: 0 10px;
      }
    }
  }
}
