// slick-carousel styles
/* Arrows */
.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    background: rgba(0,0,0,0.3);
    position: absolute;
    top: 25%;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    border-radius: 50%;
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 999;
}

@media only screen and (min-width: 768px) {
  .slick-prev, .slick-next {
    top: 50%;
  }
}

.slick-prev, .slick-next {
  &:hover, &:focus {
    background: $red;

    &:before {
      opacity: 1;
    }
  }

  &.slick-disabled:before {
      opacity: .25;
  }

  &:before {
      font-family: 'pmo-icons';
      font-size: 20px;
      line-height: 1;

      opacity: .75;
      color: white;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: 15px;

  &:before {
    content: "\e915";
  }

  [dir='rtl'] & {
    right: 0px;
    left: auto;

    &:before {
      content: "\e915";
    }
  }
}

.slick-next {
    right: 15px;

  &:before {
      content: "\e901";
  }

  [dir='rtl'] & {
      right: auto;
      left: 0px;

      &:before {
          content: "\e901";
      }
  }
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    @extend %list-unstyled;
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    text-align: center;

    li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            &:hover, &:focus {
                outline: none;

                &:before {
                    opacity: 1;
                }
            }

            &:before {
                font-family: 'slick';
                font-size: 6px;
                line-height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                content: '•';
                text-align: center;
                opacity: .25;
                color: black;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

      &.slick-active {
        button:before {
          opacity: .75;
          color: black;
        }
      }
    }
}

.slick-slide img, img.banner {
  width:auto;
  height:auto;
}
