// home-newsroom styles
.home-newsroom-links {
  .grayscale & {
    a {
      &:visited {
        .home-newsroom-title {
          color: $grayscale;
        }
      }

      &:hover, &.active {
        .home-newsroom-title {
          color: $grayscaleHover;
        }
      }
    }
  }
  .home-newsroom-title {
    @extend %headerFont;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 0;
  }

  a:hover, a.active {
    .home-newsroom-title {
      color: $hoverRed;
    }
  }

  a:visited {
    .home-newsroom-title {
      color: $visitedLink;
    }
  }
}


.home-newsroom {
  @extend %clearfix;
}

.home-newsroom-item {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #b20000;

  .meta-data, .notes .note .note-date, .notes .note & .note-date {
    margin-bottom: 20px;
  }

  .nr-title {
    width: 100%;

    a {
      margin: 0;
      line-height: normal;
    }
  }

  .nr-img {
    width: 100%;
    img {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

@media only screen and (min-width: $bpTablet) {
  .home-newsroom {
    .nr-col {
      margin-bottom: 20px;
      border-top: 1px solid rgba(178, 0, 0, 0.3);
      &:first-child {
        border-top: none;
      }
    }
  }

  .home-newsroom-item {
    float: left;
    width: 50%;
    padding-top: 20px;
    margin-top: 20px;
    border-bottom: none;
    margin-bottom: 0;

    &:nth-child(even) {
      padding-left: 20px;
    }
    &:nth-child(odd) {
      padding-right: 20px;
      border-right: 1px solid rgba(178, 0, 0, 0.3);
    }

    .meta-data, .notes .note .note-date, .notes .note & .note-date {
      margin-bottom: 0;
    }

    a {
      margin-bottom: 0;
    }

    .nr-title {
      width: 100%;
    }

    .nr-img {
      display: none;

      img {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: $bpDesktop) {
  .home-newsroom-item {
    .nr-title {
      width: 66.666%;
      float: right;

      a {
        padding-right: 10px;
      }
    }

    .nr-img {
      display: inline-block;
      width: 33.333%;
      float: right;
    }
  }
}
