// accordion styles
.accordion {
  // margin-bottom: 100px;

  .grayscale & {
    .accordion-header {
      a {
        &, &:visited {
          color: $grayscale !important;
        }

        &:hover {
          color: $grayscaleHover !important;
        }
      }
    }
  }

  .accordion-item {
    border-bottom: 1px solid rgba(178, 0, 0, 0.3);
  }

  .accordion-content {
    display: none;
  }


  .accordion-header {
    position: relative;
    display: block;
    width: 100%;
    text-decoration: none;
    @extend %bodyFont;
    font-weight: 700;
    font-size: 20px;

    a {
      display: block;
      color: $red;

      &:visited {
        color: $red !important;
      }

      &:hover {
        color: $hoverRed !important;
      }
    }
  }
}

@media only screen and (min-width: $bpTablet) {
  .accordion {
    margin-bottom: 40px;
  }
}
