// search-results styles
.search-results {
  table {
    td {
      padding: 0;
      border: 0;
      vertical-align: inherit;
    }
  }

  .gsc-selected-option-container {
    max-width: none;
  }

  .gsc-tabsArea > .gsc-tabHeader {
    height: auto;
  }

  .gsc-control-cse {
    background: transparent;
  }
}
