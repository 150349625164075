// nda-awards styles
.awards {
  margin-top: 40px;

  .lnk-award {
    display: block;
  }

  .award {
    text-align: center;
    margin-bottom: 40px;
    height: 280px;
  }
}

.award-img {
  border-radius: 50%;
  margin-bottom: 0px;

  img {
    display: inline;
  }
}

.medal-links {
  text-align: center;
  margin-bottom: 40px;

  img {
    display: inline;
    border-radius: 50%;
    margin: 0 auto 10px;
  }
}

.cta-guidelines {
  text-align: center;
}

.designation-list {
  margin-bottom: 10px;

  li {
    margin-bottom: 2px;
  }
}

.featured-award {
  margin-top: 20px;
}

.award-winner {
  display: inline-block;

  .aw-container {
    background: #FFF;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
    display: inline-block;
    text-align: left;
    padding-bottom: 20px;
    margin:5px;

    > div {
      float: left;
      width: 100%;
    }
  }

  .beta {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .aw-photo {
    img {
      width: 100%;
    }
  }
}

@media only screen and (min-width: $bpTablet) {
  .award-winner {
    .aw-container {
      width: 440px;
      margin-left: 140px;
    }
  }
}

@media only screen and (min-width: $bpDesktop) {
  .award-winner {
    .aw-container {
      width: 790px;
      padding-bottom: 0;
      margin-left: 75px;

      > div {
        width: 50%;
      }
    }
  }
}

@media only screen and (min-width: $bpLgDesktop) {
  .award-winner {
    .aw-container {
      margin-left: 175px;
    }
  }
}

.aw-designation {
  font-size: 14px;
  color: #565656;
  margin: 0;
}

.aw-medal-name, .aw-year {
  font-weight: 700;
  margin: 0;
}

.aw-medal-name {
  margin-top: 20px;
  font-weight: 700;
  margin: 0;
}

.browse-all {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 60px;
}

.aw-number-container {
  border-top: 1px solid rgba(178, 0, 0, 0.3);
  margin-bottom: 20px;
  height: 150px;

  .aw-no, .aw-type, .aw-type-lnk, .aw-no-smaller {
    margin: 0;
  }

  .aw-no {
    font-size: 40px;
    font-weight: 700;
    margin-top: 20px;
  }

  .aw-type {
    margin-top: -4px;
    color: #565656;
    font-size: 14px;
  }

  .aw-type-lnk {
    margin-top: -4px;
    font-size: 14px;
  }

  .aw-no-smaller {
    font-size: 32px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: $bpDesktop) {
  .aw-number-container {
    .aw-no {
      font-size: 60px;
    }

    .aw-no-smaller {
      font-size: 40px;
    }
  }
}

.aw-numbers-title {
  text-align: center;
}

.fulllist-awards {
  background: #FFF;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  padding: 30px;
  text-align: center;

  .fla-content {
    p {
      line-height: 36px;
    }

    select, a, p {
      display: inline;
    }
  }

  .btn-download {
    margin-left: 10px;
  }
}

.award-banner {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  margin-bottom: 60px;

  .ab-photo img {
    width: 100%;
  }

  .ab-contents {
    @extend %clearfix;
    background: #FFF;
    padding-bottom: 10px;

     > div {
      float: left;
      box-sizing: border-box;
      padding: 15px;
    }

    .ab-details {
      width: 100%;

      p {
        margin: 0;
      }
    }

    .ab-medal {
      width: 100%;
      padding-top: 30px;
      text-align: center;
    }
  }
}

@media only screen and (min-width: $bpTablet) {
  .award-banner {
    .ab-contents {
      .ab-details {
        width: 66.666%;
      }

      .ab-medal {
        width: 33.333%;
        text-align: left;
      }
    }
  }
}

.aw-details {
  box-sizing: border-box;
  padding: 0 15px;

  p.beta {
    color: #000;
    font-size: 20px;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .aw-medal-name, .aw-year {
    margin: 0;
  }

  .aw-year, .aw-medal-name {
    color: #000;
  }
}

@media only screen and (min-width: $bpTablet) {
  .aw-details {
    p.beta {
      font-size: 24px;
    }
  }
}

