// site-tool styles
.page-options {
  text-transform: uppercase;
  color: $red;
  font-size: 12px;
  margin: 0px 0 20px 0;

  .pg-opt {
    display: inline;
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }

    div {
        display: inline-block;
        vertical-align: middle;
        margin-top: 4px;
    }
  }

  a {
    margin-left: 4px;
    color: $red;
  }

  .pg-opt-icon {
    display: inline-block;
    vertical-align: top;

    &::before {
      color: $red;
    }
  }

  .icon-text-size {
    margin-top: -2px;
  }

  .icon-printer, .icon-circle-minus, .icon-plus-circle {
    margin-top: 2px;
  }

  .txtsize-disabled {
    cursor: default;
    &::before {
      color: $gray;
    }
  }
}

.txtsize-label, .print-label, .rss-label {
  margin-left: 4px;
  color: $gray;
}

@media only screen and (min-width: $bpDesktop) {
  .page-options {
    position: absolute;
    top: 36px;
    right: 15px;

    .pg-opt {
      float: left;
      margin-top: 8px;
    }
  }
}

