// sitemap styles
.site-map-menus {
  .site-map-box-menu .title {
    display: none;
  }
}

.site-map-menu {
  li {
    padding: 0;
    margin-bottom: 60px;
    list-style-type: none;
    margin-bottom: 10px;

    &::before {
      content: "•";
      display: inline-block;
      margin-right: 20px;
    }

    ul li:first-child {
      margin-top: 10px;
    }
  }
}

.sitemap {
  padding: 0;
  margin-bottom: 60px;
  color: #7f0000;

  li {
    list-style-type: none;
    margin-bottom: 10px;

    &::before {
      content: "•";
      display: inline-block;
      margin-right: 20px;
    }
  }

  ul li:first-child {
    margin-top: 10px;
  }
}
