@media print {
  body {
    font-family: 'Cardo', serif !important;
  }
  @for $i from 1 through 12 {
    .col-md-offset-#{$i} {
      margin-left: (100% / 12 * $i);
    }
  }

  @for $i from 1 through 12 {
    .col-md-#{$i} {
      width: (100% / 12 * $i);
    }
  }

  a[href]:after {
      display: none;
  }

  body {
    #base-content {
      background-image: none !important;
    }
  }

  .field-body .accordion .accordion-content {
      display: block;
  }

  .columns3 ul li {
      width: 33.33333333%;
  }

  .hero-banner {
    height: auto!important;
  }

  #block-system-main{
    page-break-after: always;
  }

  .pg-section {
    margin-top: 10px;
  }

  .notification-bar {
    display: none !important;
  }

  header {
    .navbar {
      display: none !important;
    }

    .search-toggle {
      display: none;
    }

    .search-container {
      display: none !important;
    }
  }

  footer {
    background: #fff !important;

    a {
      display: none;
    }
  }
}
