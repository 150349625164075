// pagination styles
.pagination {
  > .active {
    > a, > span {
      &, &:hover, &:focus {
        color: $gray;
        cursor: default;
        background: none;
        border: none;
      }
    }
  }

  > li {
    > a, > span {
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: $red;
      text-decoration: none;
      background: none;
      border: none;
    }

    a:hover, span {
      color: $gray;
      background: none;
      border: none;
    }
  }

  > .disabled {
    > span, > a {
      &, &:hover, &:focus {
        color: $grayscale;
        cursor: not-allowed;
        background: none;
        border: none;
      }
    }
  }
}

