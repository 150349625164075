// section-title styles
.related-title-container {
  margin-bottom: 20px;

  .rt-title {
    position: relative;
    z-index: 2;
    background: #FDFBF7;
    display: inline-block;
    margin: 0 auto;

    .rt-heading {
      margin: 0;
      display: inline;
    }

    .rt-star {
      position: relative;
      top: -4px;
      padding: 0 20px;
    }
  }

  .rt-line {
    display: block;
    position: relative;
    bottom: -6px;
    border-bottom: 1px solid rgba(178, 0, 0, 0.3);
  }
}


@media only screen and (min-width: $bpTablet) {
  .related-title-container {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: $bpDesktop) {
  .related-title-container {
    .rt-title {
      display: table;
    }

    .rt-line {
      bottom: 16px;
    }
  }
}
