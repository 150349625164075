@font-face {
  font-family: 'pmo-icons';
  src: url("../fonts/pmo-icons.eot?ohn3at");
  src: url("../fonts/pmo-icons.eot?ohn3at#iefix") format("embedded-opentype"), url("../fonts/pmo-icons.ttf?ohn3at") format("truetype"), url("../fonts/pmo-icons.woff?ohn3at") format("woff"), url("../fonts/pmo-icons.svg?ohn3at#pmo-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pmo-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-right:before {
  content: "\e901";
}

.icon-envelope:before {
  content: "\e902";
}

.icon-right, .breadcrumbs .crumb::after {
  font-family: 'pmo-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e901";
  font-size: 10px;
  margin: 0 2px;
}

.icon-magnifier:before {
  content: "\e900";
}

.icon-star {
  color: #FFDE84;
}
  .icon-star:before {
    content: "\e903";
  }

.icon-pencil5 {
  margin-right: 2px;
}

.icon-pencil5:before {
  content: "\e904";
  margin-right: 2px;
}

.icon-text-size:before {
  content: "\e905";
  font-size: 18px;
  margin-right: -2px;
}

.icon-arrow-up {
  padding-top: 8px;
}

.icon-arrow-up:before {
  content: "\e917";
}

.icon-printer:before {
  content: "\e906";
  font-size: 22px;
}

.icon-facebook:before {
  content: "\e907";
}

.icon-twitter:before {
  content: "\e908";
}

.icon-launch:before {
  content: "\e909";
}

.icon-camera2:before {
  content: "\e90a";
}

.icon-instagram:before {
  content: "\e90b";
}

.icon-quote-open:before {
  content: "\e90c";
}

.icon-new-tab:before {
  content: "\e90d";
  margin-right: 10px;
}

.icon-arrow-up-circle:before {
  content: "\e90e";
}

.icon-wifi:before {
  content: "\e90f";
  margin-right: 6px;
  vertical-align: middle;
  font-size: 30px;
}

.icon-plus-square:before {
  content: "\e910";
  margin-right: 4px;
}

.icon-minus-square:before {
  content: "\e911";
  margin-right: 4px;
}

.icon-chevron-down:after {
  content: "\e913";
  position: absolute;
  top: 2px;
  right: 4px;
}

.icon-chevron-up:after {
  content: "\e914";
  position: absolute;
  top: 2px;
  right: 4px;
}

.icon-chevron-left:before {
  content: "\e915";
}

.icon-youtube:before {
  content: "\e916";
}

.icon-circle-minus:before {
  content: "\e918";
  font-size: 22px;
}

.icon-plus-circle:before {
  content: "\e919";
  font-size: 22px;
}

.icon-info:before {
  content: "\e91a";
  vertical-align: middle;
  font-size: 24px;
}

.icon-cross:before {
  content: "\e91b";
}
