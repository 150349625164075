// hero-banner styles
.hero-banner {
  position: relative;
  // background-image: url("../images/footer-pattern.png") !important;
  // background-repeat: no-repeat !important;
  // background-position: center bottom !important;

  &.inner-banner {
    background-image: url("../images/footer-pattern.png") !important;
    background-repeat: no-repeat !important;
    background-position: center bottom !important;
  }

  .hires & .banner {
    width: auto;
    height: auto;
  }

  .hero-content {
    position: relative;
    z-index: 4;
    margin-top: 40px;

    &.gov-banner-copy {
      color: #FFF;
      -webkit-font-smoothing: antialiased;
      margin-top: 70px;
    }
  }

  .static-banner {
    position: relative;
    transition: opacity .9s;

    &.fade-out {
      pointer-events: none;
      opacity: 0;
    }
  }

  .title-container {
    transition: opacity .9s;

    &.fade-out {
      pointer-events: none;
      opacity: 0;
    }
  }

  a {
    color: #000;
  }

  .dimmer-cover {
    height: 530px;
    background: rgba(0, 0, 0, 0.3);
    background: linear-gradient(transparent 40%, rgba(0, 0, 0, 0.8));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    pointer-events: none;
    display: none;

    &.full-dim {
      background: rgba(0, 0, 0, 0.6);
    }
  }

  .ico-play {
    width: 80px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
    }
  }

  .video-banner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 530px;
    display: none;
  }
}

  @media only screen and (min-width: 992px) {
    .hires .hero-banner .banner {
      width: 100%;
      max-width: 100%;
      left: 0;
      margin-left: 0;
      width: auto;
      height: auto;
    }
  }

@media only screen and (min-width: $bpTablet) {
  .hero-banner {
    position: relative;
    height: 530px;
    overflow: hidden;

    .hires & .banner {
      position: absolute;
      top: 0;
      left: 50%;
      width: 1440px;
      height: 530px;
    }

    .hero-content {
      margin-top: 70px;

      &.gov-banner-copy {
        margin-top: 110px;
      }
    }

    .static-banner {
      position: static;
    }

    .banner {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -720px;
      width: auto;
      max-width: none;
    }

    .view-id-newsroom_page &, .view-id-video_page &, .view-id-transcript_page & {
      .banner {
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0;
        max-width: 100%;
      }
    }

    a {
      color: #FFF;
    }

    h1 {
      position: absolute;
      bottom: 40px;
      color: #FFF;
      z-index: 4;
    }

    .dimmer-cover {
      display: block;
    }
  }
}


@media only screen and (min-width: $bpDesktop) {
  .hero-banner {
    .banner {
      width: 100%;
      max-width: 100%;
      left: 0;
      margin-left: 0;
    }
  }
}
