// header-highlighter styles
.header-highlighter .topic-selector {
  margin-bottom: 0;
}

.topic-selector a.btn-pill {
    cursor: pointer;
    text-decoration: none;
}

.topic-navigator {
    margin: 0;
    font-size: 14px;

    a {
      &, &:visited, &:focus {
          color: $red;
      }
    }
}

p.topic-navigator:last-child {
  margin-bottom: 20px;
}

.topic-highlighter {
  background: $highlightYellow;
}

.header-highlighter{
  text-align: center;
  padding: 15px;

  .icon-chevron-up {
    &:after {
      content: "";
    }

    &:before, &:hover:before {
      content: "\e914";
      font-size: 18px;
      font-weight: bold;
      color: #fff;
    }
  }

  .icon-chevron-down {
    &:after {
      content: "";
    }

     &:before, &:hover:before {
      content: "\e913";
      font-size: 18px;
      font-weight: bold;
      color: #fff;
    }
  }
}

.header-highlighter-scroll{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #FDFBF7;
    z-index: 10;
    margin: 0 !important;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
}

.footnode {
  .footnode-child {
    margin-left: 30px;

    .footnode-bullet {
        margin-right: 3px;
    }
  }
}

.container-shortcodes {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video-shortcodes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.topic-show-trigger {
  position: absolute;
  bottom: 0px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  font-size:11px;
}

.topic-show-more, .topic-show-less {
  background: #7f0000;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #fff;
  width: 47px;
  padding-top: 2px;
}

@media only screen and (max-width: $bpTablet) {
  .qna {
    padding-left:15px;
    padding-right:15px;
  }
}
