// stay-connected styles
.social-title {
  margin: 0 8px;
  display: inline-block;

  &.icon-instagram, &.icon-facebook, &.icon-youtube, &.icon-twitter, &.icon2-telegram {
    &::before {
      margin-right: 6px;
      font-size: 30px;
    }
  }
}

@media only screen and (min-width: $bpTablet) {
  .social-title {
    margin: 0 25px;

    &.icon-instagram, &.icon-facebook, &.icon-youtube, &.icon-twitter, &.icon2-telegram {
      &::before {
        font-size: 40px;
      }
    }
  }
}

.social-img {
  margin: 10px 0;
}

.stay-connected {
  text-align: center;
  margin-top: 30px;
  // margin-bottom: 100px;
}

.star-line-container {
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;

  .stars {
    position: relative;
    z-index: 2;
    display: table;
    margin: 0 auto;
    padding: 0 10px;

    .star-bottom {
      margin: 0 6px;
    }
  }

  &::before, &::after {
    content: " ";
    position: absolute;
    border-top: 1px solid rgba(178, 0, 0, 0.3);
    width: 50%;
    top: 10px;
  }

  &::before {
    left: -80px;
  }

  &::after {
    right: -80px;
  }
}


@media only screen and (min-width: $bpTablet) {
  .star-line-container {
    margin-top: 60px;
    margin-bottom: 100px;
  }

  .stay-connected {
    margin-bottom: 20px;
  }
}
