// photo-aside styles
.photo-aside {
  background-color: #fff;
  border-radius: 4px;
  padding: 0px;

  .photo-card {
    margin-bottom: 20px;
    text-align: center;

    img {
      margin: auto;
    }
  }

  .region-sidebar-second {
    padding: 20px;

    h2.block-title {
      margin-top: 0;
      border-bottom: 1px solid #ddd;
      padding: 10px 0px;
    }

    ul.pagination {
      border-top: 1px solid #ddd;
      padding-top: 20px;
    }

    .views-row {
      margin-bottom: 20px;
    }
  }
}
